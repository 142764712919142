<template>
  <div>
    <div
      id="chart-panel"
      :style="{
        width: widthEchart + '%',
        height: heightEchart + '%',
        top: topEchart + '%',
        right: rightEchart + '%',
      }"
    ></div>
    <div
      v-if="contextMenuVisible"
      class="clickMenu"
      :style="style"
      @click="mapTip"
    >
      {{ mapTipText }}
    </div>
  </div>
</template>

<script>
export default {
  name: "echartsMap",
  data() {
    return {
      screenWidth1: "",
      widthEchart: 90,
      heightEchart: 90,
      topEchart: 14,
      rightEchart: -13,
      myChart: null,
      option: null,
      uploadedDataURL: null,
      geoJson: null,
      baseTexture: null,
      contextMenuVisible: false,
      mapTipText: "",
    };
  },
  mounted() {
    this.screenWidth1 = document.body.clientWidth;

    window.onresize = () => {
      return (() => {
        this.screenWidth1 = document.body.clientWidth;
      })();
    };

    this.$nextTick(() => {
      // this.screenWidth = document.body.clientWidth;

      // window.onresize = () => {
      //   return (() => {
      //     this.screenWidth = document.body.clientWidth;
      //   })();
      // };

      // 基于准备好的dom，初始化echarts实例
      this.myChart = echarts.init(document.getElementById("chart-panel"));
      console.log(this.myChart);
      // 指定图表的配置项和数据
      this.option = {};
      //=============== start ===============//
      /**
       * 🚨🚨非常重要🚨🚨
       * 下面是 网站左侧代码，核心代码
       */
      this.uploadedDataURL = require("../assets/datavalue.json");
      console.log(this.uploadedDataURL);
      // 使用刚指定的配置项和数据显示图表。
      if (this.option && typeof this.option === "object") {
        console.log(this.option);
        this.myChart.setOption(this.option);
      }

      window.addEventListener("resize", this.myChart.resize);
      this.getGeoJsonData();
    });
  },
  props: {
    screenWidth: {
      type: [Number, String],
      default: document.body.clientWidth,
    },
  },
  watch: {
    screenWidth1: {
      handler: function (val) {
        if (val < 992) {
          this.heightEchart = Number(val / 10 - 10);
          this.topEchart = 14 + Number(992 - val) / 21;
          this.rightEchart = -13 - Number(992 - val) / 21;
        } else {
          this.heightEchart = 90;
          this.topEchart = 14;
          this.rightEchart = -13;
        }
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    mapTip() {
      this.contextMenuVisible = false;
    },
    // 请求world.json文件
    getGeoJsonData() {
      const filterArray = [
        "中国",
        "白俄罗斯",
        "加拿大",
        "巴西",
        "澳大利亚",
        "印度",
        "阿根廷",
        "哈萨克斯坦",
        "阿尔及利亚",
        "刚果民主共和国",
        "沙特阿拉伯",
        "印度尼西亚",
        "苏丹",
        "利比亚",
        "伊朗",
        "蒙古",
        "秘鲁",
        "尼日尔",
        "乍得",
        "安哥拉",
        "马里",
        "南非",
        "埃塞俄比亚",
        "哥伦比亚",
        "奥地利",
        "比利时",
        "保加利亚",
        "塞浦路斯",
        "克罗地亚",
        "捷克",
        "丹麦",
        "爱沙尼亚",
        "芬兰",
        "法国",
        "德国",
        "希腊",
        "匈牙利",
        "爱尔兰",
        "意大利",
        "立陶宛",
        "波兰",
        "瑞典",
        "日本",
        "韩国",
        "朝鲜",
        "墨西哥",
        "美国",
        "俄罗斯",
      ];
      console.log(this.uploadedDataURL);
      for (const item of this.uploadedDataURL.features) {
        if (!filterArray.includes(item.properties.name)) {
          item.properties.name = "";
        }
      }
      this.geoJson = this.uploadedDataURL;
      this.getBaseTexture();
    },

    // 生成球面纹理
    getBaseTexture() {
      let canvas = document.createElement("canvas");
      echarts.registerMap("world", this.geoJson);

      this.baseTexture = echarts.init(canvas, null, {
        width: 4096,
        height: 2048,
      });

      this.baseTexture.setOption({
        backgroundColor: "#031c48", //海洋颜色
        geo: {
          type: "map",
          map: "world",
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          boundingCoords: [
            [-180, 90],
            [180, -90],
          ],
          roam: false,
          itemStyle: {
            normal: {
              areaColor: "#2455ad", //国际颜色
              borderColor: "#000c2d",
            },
            emphasis: {
              areaColor: "#357cf8",
            },
          },
          label: {
            normal: {
              fontSize: 20,
              show: true,
              textStyle: {
                color: "#fff",
              },
            },
            emphasis: {
              fontSize: 30,
              show: true,
              textStyle: {
                color: "yellow",
              },
            },
          },
        },
      });
      const _this = this;
      setTimeout(() => {
        // 添加点击事件监听器
        this.baseTexture.on("click", function (params) {
          console.log(params); // 在控制台输出点击事件的参数
          var event = params.event;
          var offsetX = event.offsetX; // 点击位置的 x 坐标（相对于图表容器）
          var offsetY = event.offsetY; // 点击位置的 y 坐标（相对于图表容器）
          console.log(offsetX, offsetY);
          _this.style = {
            left: `${offsetX / 2.2}px`,
            top: `${offsetY / 2.2}px`,
          };
          _this.contextMenuVisible = true;
          _this.mapTipText = params.name;
          // 在这里你可以根据 params 的内容来执行你想要的操作
          // 例如，你可以根据点击的省份或地区来显示更详细的信息或进行其他操作
        });
      });
      this.drawEarth();
    },

    // 绘制球体
    drawEarth() {
      this.option = {
        backgroundColor: "rgba(0,0,0,0)", // 设置为透明
        tooltip: {
          show: true,
          trigger: "item",
        },
        globe: {
          baseTexture: this.baseTexture, // 基础纹理
          globeRadius: 150, // 球面半径

          shading: "color",
          light: {
            // 光照阴影
            main: {
              color: "#fff", // 光照颜色
              intensity: 1, // 光照强度
              alpha: 40,
              beta: -30,
            },
            ambient: {
              color: "#fff",
              intensity: 1,
            },
          },
          viewControl: {
            alpha: 30,
            beta: 160,
            autoRotate: true, // 开启自动旋转
            autoRotateAfterStill: 10,
            distance: 240,
            zoomSensitivity: false,
          },
        },
        series: [
          {
            name: "世界贸易情况",
            type: "lines3D",
            coordinateSystem: "globe",
            effect: {
              show: true,
            },
            blendMode: "lighter",
            lineStyle: {
              width: 2,
            },
            data: [],
            silent: false,
          },
        ],
      };
      this.myChart.clear();
      this.myChart.setOption(this.option, true);
    },
  },
};
</script>

<style scoped>
#chart-panel {
  /* width: 90%;
    height: 90%; */
  position: fixed !important;
  /* top: 10%;
  right: -10%; */
}
.clickMenu {
  position: fixed;
  z-index: 9999;
  width: 200px;
  height: 200px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  justify-content: center;
}
</style>
