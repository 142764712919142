import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './style/index.less'


// // 在 main.js 中引入echarts
// import * as echarts from 'echarts';
// Vue.prototype.$echarts = echarts; 
 
// // 这样问题也完美解决了


Vue.config.productionTip = false

Vue.use(ElementUI, {size:'small'});

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
