<template>
  <div class="header_nav">
    <el-row>
      <el-col :span="18">
        <span class="nav_title">快乐星球</span>
        <span class="nav_href">
          <span class="nav_item" @click="goToAbout('home')">星球首页</span>
          <span class="nav_item" @click="goToAbout('aboutPlane')"
            >关于星球</span
          >
          <span class="nav_item" @click="goToAbout('mechanism')">星球机制</span>
          <span class="nav_item" @click="goToAbout('planeNews')">星球新闻</span>
          <!-- <span class="nav_item">星球白皮书</span> -->
          <span class="nav_item" @click="goToAbout('joinPlane')">加入星球</span>
          <span class="nav_item" @click="goToAbout('contactUs')">联系我们</span>
        </span>
      </el-col>
      <el-col :span="6">
        <div class="textright">
          <img
            @click="navHandle"
            v-show="!isDropdownMenu"
            class="imgright"
            :src="navMoreimg"
            alt=""
          />
          <img
            @click="navHandle"
            v-show="isDropdownMenu"
            class="imgright"
            :src="navCloseimg"
            alt=""
          />
          <!-- <span class="download_item">下载星球</span> -->
          <!-- <span class="nav_item" style="margin-right: 20px">中文 en</span> -->
        </div>
      </el-col>
    </el-row>

    <div class="dropdown_menu" v-if="isDropdownMenu">
      <span class="nav_item_li" @click="goToAbout('home')">星球首页</span>
      <span class="nav_item_li" @click="goToAbout('aboutPlane')">关于星球</span>
      <span class="nav_item_li" @click="goToAbout('mechanism')">星球机制</span>
      <span class="nav_item_li" @click="goToAbout('planeNews')">星球新闻</span>
      <span class="nav_item_li" @click="goToAbout('joinPlane')">加入星球</span>
      <span class="nav_item_li" @click="goToAbout('contactUs')">联系我们</span>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    document.addEventListener("click", this.checkClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.checkClickOutside);
  },

  data() {
    return {
      navMoreimg: require("@/assets/img/nav_more.svg"),
      navCloseimg: require("@/assets/img/nav_close.svg"),
      isDropdownMenu: false,
    };
  },
  methods: {
    checkClickOutside(event) {
      console.log(event.target.tagName);
      console.log(event.target.tagName !== "IMG");
      if (!this.$el.contains(event.target)) {
        this.isDropdownMenu = false;
      } else if (
        this.$el.contains(event.target) &&
        event.target.tagName !== "IMG"
      ) {
        this.isDropdownMenu = false;
      }
    },
    goToAbout(val) {
      switch (val) {
        case "aboutPlane":
          if (this.$route.path !== "/aboutPlane") {
            this.$router.push("/aboutPlane");
          }
          break;
        case "home":
          if (this.$route.path !== "/home") {
            this.$router.push("/home");
          }
          break;
        case "mechanism":
          if (this.$route.path !== "/mechanism") {
            this.$router.push("/mechanism");
          }
          break;
        case "joinPlane":
          if (this.$route.path !== "/joinPlane") {
            this.$router.push("/joinPlane");
          }
          break;
        case "planeNews":
          if (this.$route.path !== "/planeNews") {
            this.$router.push("/planeNews");
          }
          break;
        case "contactUs":
          if (this.$route.path !== "/contactUs") {
            this.$router.push("/contactUs");
          }
          break;
      }
    },
    navHandle() {
      this.isDropdownMenu = !this.isDropdownMenu;
    },
  },
};
</script>
<
<style lang="less" scoped>
.header_nav {
  position: fixed;
  top: 0;
  background: #000;
  z-index: 2;
  width: 100%;
  padding: 12px 0px;
  .nav_title {
    margin: 0 6px 0 24px;
    font-size: 24px;
    font-weight: 700;
    font-family: cursive;
    background: linear-gradient(
      to bottom,
      #c805ff,
      #2549fc
    ); /*设置渐变的方向从左到右 颜色从ff0000到ffff00*/

    -webkit-background-clip: text; /*将设置的背景颜色限制在文字中*/

    -webkit-text-fill-color: transparent; /*给文字设置成透明*/
    vertical-align: middle;
  }
  .nav_item {
    padding: 2px 6px;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    margin: 0 6px;
  }
  .download_item {
    padding: 2px 6px;
    background: #fff;
    color: #333;
    cursor: pointer;
    border-radius: 5px;
    font-size: 14px;
    margin: 0 6px;
  }
  .textright {
    text-align: right;
  }
  .imgright{
    height: 25px;
    width: 25px;
    padding-right: 16px;
    cursor: pointer;
  }
  .marright {
    margin-right: 24px;
  }
}
.imgright{
    display: none;
  }
.dropdown_menu {
  position: absolute;
  right: 2rem;
  top: 50px;
  width: 300px;
  background:rgba(255,255,255,0.1);
  backdrop-filter:blur(15px);
  border-radius:10px;
  overflow: hidden;
  display: none;
  .nav_item_li{
    padding:0.7rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 0.9rem;
  }
}
@media(max-width:992px){
  .nav_href{
    display: none;
  }
  .dropdown_menu{
    display: block;
  }
  .imgright{
    display: inline-block;
  }
}

@media(max-width:576px){
  .dropdown_menu {
    left: 2rem;
    width: unset;
  }
}

</style>
